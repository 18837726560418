<!-- 单科考试-学员资料 -->
<template>
    <div class="SingleData">
        <!-- 上面部分 -->
        <div class="top">
            <span style="font-size: 16px; font-weight: bold;">学员资料</span>
            <el-button class="el-btn" size="small" @click="goBack">
                返回
            </el-button>
        </div>
        <!-- 表格 -->
        <el-table :data="tableData" border stripe style="width:97.8%;flex: 1;" id="out-table" height="600">
            <!-- 复选按钮 -->
            <el-table-column type="selection" align="center" width="50">
            </el-table-column>
            <el-table-column prop=""  label="序号" align="center" width="80">
            </el-table-column>
            <el-table-column prop="" sortable label="准考证号" align="center" width="120">
            </el-table-column>
            <el-table-column prop="" sortable label="学员姓名" align="center" width="150">
            </el-table-column>
            <el-table-column prop="" sortable label="拼音名" align="center" width="150">
            </el-table-column>
            <el-table-column prop="" sortable label="性别" align="center" width="100">
            </el-table-column>
            <el-table-column prop="" sortable label="出生日期" align="center" width="150">
            </el-table-column>
            <el-table-column prop="" sortable label="国籍" align="center" width="100">
            </el-table-column>
            <el-table-column prop="" sortable label="民族" align="center" width="100">
            </el-table-column>
            <el-table-column prop="" sortable label="学科" align="center" width="120">
            </el-table-column>
            <el-table-column prop="" sortable label="等级" align="center" width="120">
            </el-table-column>
            <el-table-column prop="" sortable label="场地" align="center" width="200">
            </el-table-column>
            <el-table-column prop="" sortable label="考试日期" align="center" width="150">
            </el-table-column>
            <el-table-column prop="" sortable label="考试时间" align="center" width="150">
            </el-table-column>
            <el-table-column prop="" sortable label="机构名称" align="center" width="200">
            </el-table-column>
            <el-table-column prop="" sortable label="说明" align="center" width="350">
            </el-table-column>
        </el-table>
        <!--分页 -->
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
            :page-sizes="[20, 50,100,200,500,1000]" :page-size="10" background
            layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                tableData: [],
                currentPage: 1,
                currentLimit: 20,
                total: 0,
            }
        },
        created() {
            // console.log(this.$route.query.id)
            // this.$request({
            //     url: '/api/Exam/detail',
            //     method: 'POST',
            //     data: {
            //         id: this.$route.query.id,
            //     }
            // }).then(res => {
            //     console.log(res.data)
            //     this.tableData=res.data
            // })
        },
        methods: {
            goBack() { //返回
                this.$router.go(-1);
            },
            handleSizeChange(val) {
                console.log(`每页 ${val} 条`);

            },
            handleCurrentChange(val) {
                console.log(`当前页: ${val}`);

            },
        }
    }
</script>

<style scoped="scoped">
    .SingleData {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    /* 上面部分 */
    .top {
        padding-top: 25px;
        margin: 0px 24px 20px 28px;
        display: flex;
        justify-content: space-between;
    }

    .el-btn {
        background: #18BC9C;
        padding-left: 25px;
        background-position: 5px 7px;
        display: inline-block;
        background-image: url(../../assets/back.png);
        background-repeat: no-repeat;
        color: #FFFFFF;
    }

    /* 表格 */
    .el-table {
        margin: 20px;
    }

    ::v-deep .el-table__cell {
        color: #000;
        padding: 0;
        height: 30px;
    }

    /* 分页 */
    .el-pagination {
        margin: 20px;
        margin-top: 0px;
    }

    ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
        background-color: #2C3E50 !important;
    }
</style>
